import { UserContext } from "contexts/UserProvider";
import React, { useContext } from "react";
import "./ProfileInformation.scss";
const ProfileInfo = ({ ventures, screen }) => {
  const user = useContext(UserContext);
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <h2 className="circle text-white text-start bg-success rounded-circle bg-opacity-25 border border-dark  from text-uppercase d-flex justify-content-center align-items-center">
        {user?.firstName[0]}
        {user?.lastName[0]}
      </h2>
      <div className="d-flex justify-content-center align-items-center flex-column ">
        <h3 className="text-left  text-capitalize">
          {`${user.firstName} 
        ${user.lastName}`}
        </h3>

        <p className="text-left">{user.email}</p>
        {ventures?.companyName && (
          <div className="border-bottom border-top border-dark">
            <h3 className="my-1 text-left text-primary">
              {ventures?.companyName}
            </h3>
          </div>
        )}
        {screen === "entrepreneurDetail" ? (
          <div className="mx-3 my-3 border border-dark px-1 py-2">
            <h5 className="text-center">Maximize your odds of success:</h5>
            <small>
              Inquire with your designated deal lead about any upcoming
              syndication events, and if such events are scheduled, request
              their support in advocating for you.
            </small>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
