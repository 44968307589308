import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import React, { useEffect, useState, useRef } from "react";
import { get } from "../../../utils/DeApi";
import Loader from "components/Loader/Loader";
import { useParams } from "react-router-dom";
import NoVote from "./NoVote";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import "./VoteSummary.scss";

const CONFLICT_OF_INTEREST = "Conflict of interest";

const VoteSummary = () => {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [votesPrediction, setVotesPrediction] = useState([]);
  const subscribedPromises = useRef([]);

  const { id } = useParams();

  const getVotesStats = (votes = []) => {
    const votesStats = {
      "100%": {
        count: 0,
        value: 100,
      },
      "75%": {
        count: 0,
        value: 75,
      },
      "50%": {
        count: 0,
        value: 50,
      },
      "25%": {
        count: 0,
        value: 25,
      },
      "0%": {
        count: 0,
        value: 0,
      },
      "Conflict of interest": {
        count: 0,
        value: 0,
      },
    };

    for (let i = 0; i < votes.length; i++) {
      const { choice } = votes[i];

      if (choice in votesStats) {
        votesStats[choice].count += 1;
      }
    }

    return votesStats;
  };

  const getSumProductsAndTotalVotes = (votesStats) => {
    const votesPrediction = {
      sumProducts: 0,
      totalVotes: 0,
      sumProductsPercentage: 0,
    };

    for (let key in votesStats) {
      // we don't count conflict of interest
      if (key !== CONFLICT_OF_INTEREST) {
        const currentVote = votesStats[key];
        const sumProduct = currentVote?.count * currentVote?.value;
        votesPrediction.sumProducts += sumProduct;
        votesPrediction.totalVotes += currentVote?.count;
      }
    }

    // this check is necessary to avoid NaN
    // 0 divided by 0 creates a NaN
    // we check if the value is greater than zero
    if (votesPrediction.sumProducts && votesPrediction.totalVotes) {
      votesPrediction.sumProductsPercentage =
        votesPrediction.sumProducts / votesPrediction.totalVotes;
    }

    return votesPrediction;
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchVentures = get(`/ventures/${id}`);
    fetchVentures.promise
      .then(({ data }) => {
        const votesStats = getVotesStats(data?.currentVentureRound?.votes);
        const results = getSumProductsAndTotalVotes(votesStats);
        setVotesPrediction(results);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setError(error);
        setIsLoading(false);
      });
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [id]);

  const renderVoteSummary = () => {
    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (votesPrediction.totalVotes === 0) return <NoVote />;

    return (
      <>
        <Row>
          <p>
            Periodically investors make a prediction on how likely a startup is
            to fill their current round. Their options were 0%, 25%, 50%, 100%,
            or they could indicate they had a conflict of interest.
            <br />
            <br />
            <span className="text-primary fw-bold">
              * The investor prediction information is confidential and is only
              accessible to the Admin of the Syndication group.
            </span>
            <br />
            <br />
            <span>Here are the results we have for you to date:</span>
          </p>
        </Row>
        <Row>
          <Col>
            <Card className="votes-card">
              <Card.Header>
                <Card.Title>
                  Investors prediction on your liklihood of filing this round
                </Card.Title>
              </Card.Header>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <p className="display-1">
                  {votesPrediction.sumProductsPercentage?.toFixed(0)}%
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="votes-card">
              <Card.Header>
                <Card.Title># of investors who made a prediction</Card.Title>
              </Card.Header>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <p className="display-1">{votesPrediction.totalVotes}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Card>
      <Card.Body>
        <h2 className="text-capitalize text-primary">Votes Feedback</h2>
        <hr />
        {renderVoteSummary()}
      </Card.Body>
    </Card>
  );
};

export default VoteSummary;
