import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Card, Button, Row, Col } from "react-bootstrap";
import { get } from "../../../../utils/DeApi";
import { UserContext, UserDispatchContext } from "contexts/UserProvider";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import ProfileInformation from "components/Dashboard/ProfileInformation/ProfileInformation";
const Venture = () => {
  const subscribedPromises = useRef([]);
  const [formData, setFormData] = useState([]);
  const [nominations, setNominations] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const user = useContext(UserContext);
  const setUser = useContext(UserDispatchContext);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    const fetchVentures = get(`/ventures`);
    const fetchNominations = get(`/nominations/${user?.email}`);

    Promise.all([fetchVentures.promise, fetchNominations.promise])
      .then((responses) => {
        setProfileData(responses?.[0].data);
        setNominations(responses?.[1]?.data?.syndicationGroups);
        const userVal = responses?.[0]?.data?.filter(
          (data) => data?.createdBy === user?.userId
        );
        setFormData(userVal);
        setUser((prevUser) => ({
          ...prevUser,
          ventureId: userVal?.[0]?.id,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(fetchVentures, fetchNominations);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [user.userId]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler />;
  return (
    <>
      <Row className="my-5">
        <Col lg={3}>
          <ProfileInformation />
        </Col>
        <Col lg={9}>
          {formData?.length === 0 && (
            <Card className="m-auto">
              <Card.Body>
                <p className="text-center text-primary fs-4 mx-auto my-2 w-75 border-bottom border-dark">
                  Welcome To ACA Syndication Application
                </p>

                {!!nominations?.length ? (
                  <Alert className="mx-auto my-3" variant="info">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <p>
                        You can create a new profile by clicking the button
                        below
                      </p>
                      <Link to={`/venture-profile`}>
                        <Button className="bg bg-primary text-white">
                          Create Profile
                        </Button>
                      </Link>
                    </div>
                  </Alert>
                ) : (
                  <Alert className="mx-auto my-3" variant="warning">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <p>
                        You are not able to create profile because you are not
                        nominated!!
                      </p>
                    </div>
                  </Alert>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
        {formData.length !== 0 && navigate(`/ventures/${formData[0]?.id}`)}
      </Row>
    </>
  );
};

export default Venture;
