import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import "./App.css";

import Signup from "./Signup/Signup";
import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import NoMatch from "./NoMatch/NoMatch";

import { UserContext, UserProvider } from "contexts/UserProvider";

import Dashboard from "components/Dashboard/Dashboard";

import Content from "components/Content/Content";
import { EditProfile } from "components/Dashboard/VentureProfile/EditProfile/EditProfile";
import VoteProfile from "components/Dashboard/VentureProfile/VoteProfile/VoteProfile";
import ReadMe from "components/ReadMe/ReadMe";
import VentureProfile from "components/Dashboard/VentureProfile/VentureProfile";
import InvestorDashboard from "components/InvestorDashboard/InvestorDashboard";
import VentureDetails from "components/Dashboard/VentureProfile/VentureDetails/VentureDetails";
import GoogleCallback from "./Login/Tabs/Investor/GoogleCallback";
import { CustomCallback } from "./Login/Tabs/Investor/LinkedinCallback";
import UnsubscribeEmail from "./UnsubscribeEmail/UnsubscribeEmail";
import ContentProvider from "contexts/ContentContext";
import AllArticles from "./WhatsNew/AllArticles";
/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
const App = () => {
  return (
    <div className="Main">
      <ScrollToTop />
      <UserProvider>
        <ContentProvider>
          <Router>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route index element={<Dashboard />} />
                <Route path={"venture-profile"} element={<VentureProfile />} />
                <Route path="/view-profile" element={<InvestorDashboard />} />
                <Route path="/etiquette" element={<ReadMe />} />
                <Route path="contents/*" element={<Content />} />
                <Route path="logout" element={<Logout />} />
                <Route path={"profile/:id/edit"} element={<EditProfile />} />
                <Route path={"deals/:id"} element={<VoteProfile />} />
                <Route path="ventures/:id" element={<VentureDetails />} />
                <Route
                  path="unsubscribe-email"
                  element={<UnsubscribeEmail />}
                />
                <Route path="all-updates" element={<AllArticles />} />
                <Route path="logout" element={<Logout />} />
              </Route>
              <Route path="/linkedin" element={<CustomCallback />} />
              <Route path="/auth/google" element={<GoogleCallback />} />
              <Route path="signup" element={<Signup />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />

              <Route index element={<NoMatch />} />
            </Routes>
          </Router>
        </ContentProvider>
      </UserProvider>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  return user ? <Outlet /> : <Navigate to="login" state={{ from: location }} />;
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

export default App;
