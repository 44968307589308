import React, { useState } from "react";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import "./Login.scss";
import TextLogo from "../TextLogo/TextLogo";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";
import Investor from "./Tabs/Investor/Investor";
import Entrepreneur from "./Tabs/Entrepreneur/Entrepreneur";
import Copyright from "../Copyright/Copyright";

const Login = () => {
  const [role, setRole] = useState();

  if (!role)
    return (
      <Container className="mt-4 mb-4">
        <Row className="justify-content-center">
          <Col xl={5} lg={6} md={8} sm={10}>
            <div className="text-center Logo">
              <h2 className="my-3 text-primary">
                <img
                  className="img-responsive w-75 mx-auto"
                  alt="Logo"
                  src="/etiquetteLogo.png"
                />
              </h2>
            </div>
            <Card className="border-0 shadow-sm">
              <Card.Body>
                <Row className="my-3">
                  <Col xs={12} sm={6}>
                    <div className="d-grid">
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          localStorage.setItem("role", "entrepreneur");
                          setRole("entrepreneur");
                        }}
                      >
                        Entrepreneur Sign In
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className="d-grid">
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          localStorage.setItem("role", "investor");
                          setRole("investor");
                        }}
                      >
                        Investor Sign In
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container className="Login mt-4 mb-4">
      <RedirectIfAuthenticated />
      <Row className="justify-content-center">
        <Col xl={5} lg={6} md={8} sm={10}>
          <TextLogo />
          <Card className="border-0 shadow-sm">
            <Card.Body>
              {role === "entrepreneur" && <Entrepreneur />}
              {role === "investor" && <Investor />}
            </Card.Body>
          </Card>
          <p className="text-center">
            {role === "investor" ? (
              <Button
                variant="link"
                active={role === "entrepreneur"}
                onClick={() => {
                  localStorage.setItem("role", "entrepreneur");
                  setRole("entrepreneur");
                }}
              >
                Entrepreneur Sign In
              </Button>
            ) : (
              <Button
                variant="link"
                active={role === "investor"}
                onClick={() => {
                  localStorage.setItem("role", "investor");
                  setRole("investor");
                }}
              >
                Investor Sign In
              </Button>
            )}
          </p>
          <div className="my-3 text-center">
            <p className="text-dark mb-1">
              <small>
                <Copyright />
              </small>
            </p>
            <p className="text-dark my-2">Powered by PixelEdge.io</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
