import { useContext } from "react";

import { Col, Container, Row } from "react-bootstrap";

import Header from "components/App/Header/Header";

import { UserContext } from "contexts/UserProvider";

import Footer from "components/App/Footer/Footer";
import InvestorDashboard from "components/InvestorDashboard/InvestorDashboard";

import Venture from "./VentureProfile/Venture/Venture";
import DataLayer from "utils/DataLayer";

const Dashboard = () => {
  const user = useContext(UserContext);

  return (
    <>
      <Header />
      <DataLayer />
      <Container fluid className="MainContent">
        <Row>
          <Col>
            {user.role === "investor" ? <InvestorDashboard /> : <Venture />}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
