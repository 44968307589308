import React, { useState, useContext } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { login, get } from "utils/BeeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { login as loginDE } from "utils/DeApi";
import { isValidEmail } from "utils/StringUtils";
import {
  UserDispatchContext,
  UserDispatchDestroyContext,
} from "contexts/UserProvider";
import { Link } from "react-router-dom";
import { logout } from "utils/BeeApi";
import { logout as logoutDE } from "utils/DeApi";
export default function Entrepreneur() {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const setUser = useContext(UserDispatchContext);
  const [touched, setTouched] = useState({
    emailValidator: false,
    passwordValidator: false,
  });
  function handleEmailChange(event) {
    let _email = event.target.value;
    setEmail(_email);
    setEmailValid(isValidEmail(_email));
    setFormSubmitted(false);
  }

  function handlePasswordChange(event) {
    let password = event.target.value;
    setPassword(password);
    setPasswordValid(!!password.length);
    setFormSubmitted(false);
  }

  function toggleVisiblePassword() {
    setShowPassword(!showPassword);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (emailValid && passwordValid) {
      setError("");
      setIsLoading(true);
      login({ email: email, password: password })
        .then((response) => {
          return loginDE({
            ...response.data,
            email: email,
          });
        })
        .then((response) => {
          const base64Url = response.data.token.split(".")[1];
          const base64 = base64Url.replace("-", "+").replace("_", "/");
          const decoded = JSON.parse(atob(base64));
          return { token: response.data.token, role: decoded.role };
        })
        .then(({ role }) => {
          if (role === "entrepreneur") return get("user/anon").promise;
        })
        .then((response) => {
          setError(null);
          setIsLoading(false);
          setUser({ ...response.data, role: "entrepreneur" });
        })

        .catch((error) => {
          setError(error);
          logout().then(() => {
            return logoutDE();
          });
          setIsLoading(false);
        });
    }
  }
  return (
    <div>
      <h3 className="my-3 text-center">Entrepreneur Sign In</h3>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="my-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!emailValid && touched.emailValidator && email !== ""}
            placeholder="Enter email"
            onBlur={() => {
              setTouched({ emailValidator: true });
            }}
            required
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              isInvalid={
                !passwordValid && touched.passwordValidator && password !== ""
              }
              placeholder="Password"
              onBlur={() => {
                setTouched({ passwordValidator: true });
              }}
              required
            />
            <Button
              variant="light"
              className="border-top border-end border-start pt-0 border-bottom"
              onClick={toggleVisiblePassword}
            >
              {!showPassword ? (
                <span className="material-icons-outlined">visibility_off</span>
              ) : (
                <span className="material-icons-outlined">visibility</span>
              )}
            </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            This is required
          </Form.Control.Feedback>
        </Form.Group>

        {error && <ErrorHandler error={error} />}
        <div className="d-grid gap-2 mb-3">
          <Button
            type="submit"
            className="text-white"
            disabled={isLoading || email === "" || password === ""}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Sign in
          </Button>
        </div>
        <p className="text-center">
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
        <p className="text-center">
          New to ACA Northeast Syndication Application?{" "}
          <Link to="/signup">Get Started.</Link>
        </p>
      </Form>
    </div>
  );
}
